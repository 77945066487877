import React from 'react';
import { graphql } from 'gatsby';
import get from 'lodash/get';
import Helmet from 'react-helmet';

import Media from 'components/Media';
import ScrollProvider from 'components/ScrollProvider';
import FrameController from 'components/HardwareFeature/FrameController';

import StickyNavigation from 'components/StickyNavigation';

import HardwareFeature from 'components/HardwareFeature';
import HardwareStatic from 'components/HardwareStatic';

import './hardware.scss';

class HardwarePage extends React.Component {
    render() {
        const { site } = get(this, 'props.pageContext');
        const { title, sections, touchPoints, pilotPlan } = get(
            this,
            'props.data.hardware',
        );

        return (
            <ScrollProvider>
                <div className="hardware">
                  <Helmet>
                    <title>{title || site.title || 'Mira'}</title>
                  </Helmet>
                    <StickyNavigation
                        sticky={true}
                        pages={site.navigationItems}
                        sections={[]}
                        loginLink={site.loginLink}
                        callToAction={site.callToAction}
                    />
                    <Media
                        query={
                            '(min-width: 768px) and (orientation: landscape)'
                        }
                    >
                        {desktop =>
                            desktop ? (
                                <FrameController
                                    anchors={[
                                        null,
                                        ...sections.map(({ slug }) => slug),
                                    ]}
                                >
                                    <HardwareFeature
                                        site={site}
                                        content={sections}
                                        touchpoints={touchPoints}
                                        pilotPlan={pilotPlan}
                                    />
                                </FrameController>
                            ) : (
                                <HardwareStatic
                                    site={site}
                                    content={sections}
                                    touchpoints={
                                        touchPoints &&
                                        touchPoints.slice().reverse()
                                    }
                                    pilotPlan={pilotPlan}
                                />
                            )
                        }
                    </Media>
                </div>
            </ScrollProvider>
        );
    }
}

export default HardwarePage;

export const pageQuery = graphql`
    query HardwarePageQuery($id: String!) {
        site: allContentfulWebsite {
            edges {
                node {
                    id
                    title
                    navigationItems {
                        label
                        href
                    }
                    callToAction {
                        label
                        href
                    }
                    loginLink {
                        label
                        href
                    }
                    footerNavigationItems {
                        label
                        href
                    }
                    facebook
                    instagram
                    twitter
                    linkedin
                    copyright
                }
            }
        }
        hardware: contentfulHardwarePage(contentful_id: { eq: $id }) {
            id: contentful_id
            title
            sections {
                label
                text
                slug
                image {
                    file {
                        url
                    }
                }
            }
            touchPoints
            pilotPlan {
                heading
                text
                call: callToAction {
                    label
                    href
                }
            }
        }
    }
`;
