import { useState, useEffect, useRef } from 'react';

function useHover() {
    const [value, setValue] = useState(false);

    const ref = useRef(null);

    const mouseOver = () => {
        console.log('Over');
        setValue(true);
    };

    const mouseOut = () => {
        console.log('Out');
        setValue(false);
    };

    useEffect(() => {
        const { current } = ref;

        if (current) {
            current.addEventListener('mouseover', mouseOver);
            current.addEventListener('mouseout', mouseOut);

            return () => {
                current.removeEventListener('mouseover', mouseOver);
                current.removeEventListener('mouseout', mouseOut);
            };
        }

        return () => {};
    }, [ref.current]);

    return [ref, value];
}

export default useHover;
