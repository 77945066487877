import React, { useState } from 'react';
import { motion } from 'framer-motion';
import get from 'lodash/get';

import PilotPlan from 'components/PilotPlan';
import SiteFooter from 'components/SiteFooter';

import './HardwareStatic.scss';

export const Touchpoint = ({ x, y, active = false, onClick }) => {
    return (
        <g onClick={onClick}>
            <motion.circle
                cx={x}
                cy={y}
                fill="rgba(255,255,255,0.5)"
                initial="hidden"
                animate={active ? 'active' : 'visible'}
                variants={{
                    hidden: {
                        r: 0,
                    },
                    visible: {
                        r: 10,
                        fill: 'rgba(255,255,255,0.5)',
                    },
                    active: {
                        r: 16,
                        fill: 'rgba(105,155,207,0.5)',
                    },
                }}
            />
            <motion.circle
                cx={x}
                cy={y}
                fill="rgba(255,255,255,1.0)"
                initial="hidden"
                animate={active ? 'active' : 'visible'}
                variants={{
                    hidden: {
                        r: 0,
                    },
                    visible: {
                        r: 5,
                        fill: 'rgba(255,255,255,1.0)',
                    },
                    active: {
                        r: 8,
                        fill: 'rgba(105,155,207,1.0)',
                    },
                }}
            />
        </g>
    );
};

export const Visor = ({ touchpoints = [] }) => {
    const bullets = [
        { x: '10%', y: '45%', text: 'Ac velit ad' },
        { x: '50%', y: '40%', text: 'Nostrud uxor virtus' },
        { x: '90%', y: '40%', text: 'Vereor dui morbi' },
    ].map((props, index) => ({ ...props, text: touchpoints[index] }));

    const [activeBullet, setBullet] = useState(0);

    return (
        <div className="card touchpoints">
            <h2>Prism Pro</h2>
            <div className="product-container pro">
                <svg aria-hidden="true" role="img" viewBox="0 0 320 320">
                    {bullets.map(({ x, y }, index) => (
                        <Touchpoint
                            key={index}
                            x={x}
                            y={y}
                            active={activeBullet === index}
                            onClick={() => setBullet(index)}
                        />
                    ))}
                </svg>
            </div>
            <div className="hover-info">{bullets[activeBullet].text}</div>
        </div>
    );
};

export const Hardhat = ({ touchpoints = [] }) => {
    const bullets = [
        { x: '40%', y: '60%', text: 'Nostrud uxor virtus' },
        { x: '50%', y: '20%', text: 'Ac velit ad' },
    ].map((props, index) => ({ ...props, text: touchpoints[index] }));

    const [activeBullet, setBullet] = useState(0);

    return (
        <div className="card touchpoints">
            <h2>Prism Pro Hardhat</h2>
            <div className="product-container hardhat">
                <svg aria-hidden="true" role="img" viewBox="0 0 320 320">
                    {bullets.map(({ x, y }, index) => (
                        <Touchpoint
                            key={index}
                            x={x}
                            y={y}
                            active={activeBullet === index}
                            onClick={() => setBullet(index)}
                        />
                    ))}
                </svg>
            </div>
            <div className="hover-info">{bullets[activeBullet].text}</div>
        </div>
    );
};

export const HardwareStatic = ({ site, content = [], touchpoints = [], pilotPlan }) => (
    <div className="hardware-static">
        <h1 className="intro">
            Introducing the
            <br />
            <b>Prism Pro</b>
        </h1>
        {content &&
            content.map(({ label, text, image, slug }, index) => (
                <div key={index} className="card">
                    <a name={slug}></a>
                    <div className="image-container">
                        <img src={get(image, 'file.url')} />
                    </div>
                    <div className="card-info">
                        <span className="label">{label}</span>
                        <h3>{text}</h3>
                    </div>
                </div>
            ))}
        <Visor touchpoints={touchpoints && touchpoints.slice(0, 3)} />
        <Hardhat touchpoints={touchpoints && touchpoints.slice(3, 5)} />
        <PilotPlan {...pilotPlan} />
        <SiteFooter
            links={site.footerNavigationItems}
            social={site}
            copyright={site.copyright}
        />
    </div>
);

export default HardwareStatic;

export const Segment = ({ children }) => (
    <div className="segment">{children}</div>
);

export const Figure = ({ src }) => <img src={src} />;

export const CaptionLabel = ({ children }) => <div>{children}</div>;

export const Caption = ({ children }) => <div>{children}</div>;
