import React, { Component } from 'react';
import { motion } from 'framer-motion';

import { useFrameContext } from '../FrameController';

export class CanvasFlipbook extends Component {
    constructor(props) {
        super(props);

        this.canvasRef = React.createRef();
    }

    render() {
        return (
            <motion.canvas
                ref={this.canvasRef}
                width="1920"
                height="1080"
                css={{
                    position: 'absolute',
                    left: '0',
                    top: '0',
                    width: '100%',
                    height: '100%',
                }}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { delay: 0.5 } }}
            ></motion.canvas>
        );
    }

    componentDidMount() {
        const { current: ref } = this.canvasRef;

        this.ctx = ref.getContext('2d');
    }

    componentDidUpdate() {
        if (
            !this.pending &&
            this.props.loaded &&
            this.props.href !== this.currentHref
        ) {
            this.pending = true;

            window.requestAnimationFrame(() => {
                this.ctx.drawImage(this.props.img, 0, 0);
                this.pending = false;
                this.currentHref = this.props.href;
            });
        }
    }
}

export const FlipbookWrapper = () => {
    const frameContext = useFrameContext();

    return <CanvasFlipbook {...frameContext} />;
};

export default FlipbookWrapper;
