import { useState, useEffect } from 'react';

export const Media = ({ query, children }) => {
    const [result, setResult] = useState(false);

    useEffect(() => {
        const test = () => setResult(window.matchMedia(query).matches);

        if (typeof window !== 'undefined') {
            test();

            window.addEventListener('resize', test);

            return () => {
                window.removeEventListener('resize', test);
            };
        }

        return () => {};
    }, []);

    return children(result);
};

export default Media;
