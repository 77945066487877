import React, { useState, useEffect, useContext, createContext } from 'react';

import { useScrollContext } from 'components/ScrollProvider';

const SCROLL_RATE = 15;

const segments = [
    {
        density: 1,
        frames: [1, 100],
    },
    {
        density: 1,
        frames: [122, 946],
    },
];

const pagination = [
    {
        frame: 0,
    },
    {
        frame: 46,
        anchor: '1',
    },
    {
        frame: 316,
        anchor: '2',
    },
    {
        frame: 418,
        anchor: '3',
    },
    {
        frame: 516,
        anchor: '4',
    },
    {
        frame: 940,
        anchor: '5',
    },
    {
        frame: 946,
        anchor: '6',
    },
];

function calculatePage(frame) {
    let page = 0;

    while (page < pagination.length && frame >= pagination[page].frame)
        page += 1;

    return page - 1;
}

function buildFrameMap() {
    let i = 0;

    const map = {};
    const assets = {};

    segments.forEach(segment => {
        const { frames, density = 1 } = segment;

        const duration = segment.duration || frames[1] - frames[0] + 1;
        const step = (frames[1] - frames[0]) / (duration - 1);

        for (let j = 0; j < duration; j += 1) {
            map[i] =
                frames[0] +
                Math.round((1 / density) * Math.floor(density * j) * step);

            if (!assets[map[i]]) {
                assets[map[i]] = {
                    virtualFrame: i,
                    href: `/assets/mira-prismpro-v8-hi/frame-${String(
                        map[i],
                    ).padStart(3, '0')}.jpg`,
                };
            }

            i += 1;
        }
    });

    return { map, assets };
}

function buildReverseMap(map) {
    const keys = Object.keys(map);
    const reverse = {};

    let last;
    keys.forEach(key => {
        if (last) {
            for (let i = last + 1; i < map[key]; i += 1) {
                if (!reverse[i]) reverse[i] = key;
            }
        }

        if (!reverse[map[key]]) reverse[map[key]] = key;

        last = map[key];
    });

    return reverse;
}

function loadAssets(assets, progress) {
    const keys = Object.keys(assets);

    let pending = false;
    let loadedCount = 0;

    function updateProgress() {
        if (!pending) {
            pending = true;

            window.requestAnimationFrame(() => {
                progress(loadedCount / keys.length);

                pending = false;
            });
        }
    }

    keys.forEach(key => {
        const frame = assets[key];
        const img = new Image();

        if (!frame) {
            console.error(`Invalid frame at ${key}`);
            return;
        }

        frame.img = img;
        frame.loaded = false;

        img.addEventListener(
            'load',
            () => {
                loadedCount += 1;
                frame.loaded = true;

                updateProgress();
            },
            false,
        );

        img.addEventListener(
            'error',
            () => {
                console.error('Load error', frame.href);
            },
            false,
        );

        img.src = frame.href;
    });
}

const defaultContext = {};

export const FrameContext = createContext(defaultContext);

function calculateScrollHeight(frameCount = 0) {
    const base = typeof window === 'undefined' ? 0 : window.innerHeight;

    return base + SCROLL_RATE * frameCount;
}

export const ProgressIndicator = ({ progress = 0 }) => (
    <div
        css={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: '#000',
        }}
    >
        <div
            css={{
                position: 'absolute',
                top: '50%',
                left: 0,
                width: '100%',
                textAlign: 'center',
                color: '#FFF',
                fontSize: '4.68rem',
                transform: 'translate(0, -50%)',
            }}
        >
            {Math.round(100 * progress)}
            <span css={{ fontWeight: 100, fontSize: '3rem' }}>%</span>
            <div css={{ width: '10vw', margin: '0 auto' }}>
                <div
                    css={{
                        margin: '0 auto 0 0',
                        height: '5px',
                        backgroundColor: '#4B9AF3',
                    }}
                    style={{ width: `${100 * progress}%` }}
                ></div>
                <div
                    css={{
                        backgroundColor: '#4B9AF3',
                        height: '1px',
                        marginTop: '-3px',
                    }}
                ></div>
            </div>
        </div>
    </div>
);

export const FrameController = ({ children, anchors = [] }) => {
    const [frameMap, setFrameMap] = useState();
    const [reverseMap, setReverseMap] = useState();
    const [assetMap, setAssetMap] = useState();
    const [loadProgress, setLoadProgress] = useState(0);

    useEffect(() => {
        const { matches: mobile } = window.matchMedia('(max-width: 767px)');

        if (!mobile) {
            const { map, assets } = buildFrameMap();
            const reverse = buildReverseMap(map);

            setFrameMap(map);
            setReverseMap(reverse);
            setAssetMap(assets);

            loadAssets(assets, progress => setLoadProgress(progress));
        }
    }, []);

    const { top: scrollTop, scrollTo, jumpTo } = useScrollContext();

    const frameCount = frameMap ? Object.keys(frameMap).length - 1 : 0;

    const index = frameMap
        ? frameMap[
              Math.min(
                  Math.floor(Math.max(scrollTop, 0) / SCROLL_RATE),
                  frameCount,
              )
          ]
        : 0;

    const { href, img, loaded } = assetMap ? assetMap[index] : {};

    const page = calculatePage(index);

    function scrollToFrame(frame) {
        scrollTo(reverseMap[frame] * SCROLL_RATE);
    }

    function scrollToPage(value) {
        scrollToFrame(pagination[value].frame);
    }

    function jumpToPage(value) {
        jumpTo(reverseMap[pagination[value].frame] * SCROLL_RATE);
    }

    return (
        <FrameContext.Provider
            value={{
                loadProgress,
                index,
                page,
                anchor: anchors[page] || pagination[page].anchor,
                href,
                img,
                loaded,
                pagination: pagination.map((item, i) => ({
                    ...item,
                    anchor: anchors[i] || item.anchor,
                })),
                scrollHeight: calculateScrollHeight(frameCount),
                scrollToFrame,
                scrollToPage,
                jumpToPage,
            }}
        >
            {/*loadProgress < 1*/!!0 ? ( // disabling the progress indicator for now
                <ProgressIndicator progress={loadProgress} />
            ) : (
                children
            )}
        </FrameContext.Provider>
    );
};

export function useFrameContext() {
    return useContext(FrameContext);
}

export default FrameController;
