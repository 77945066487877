import React from 'react';
import { motion } from 'framer-motion';

export const BlueDot = ({ x = 0, y = 0, size = 24, anchor = 'left' }) => (
    <svg
        aria-hidden="true"
        role="img"
        viewBox="0 0 512 512"
        css={{
            position: 'absolute',
            transform: 'translate(-50%, -50%)',
        }}
        style={{
            [anchor === 'left' ? 'right' : 'left']: '100%',
            [anchor === 'left' ? 'marginRight' : 'marginLeft']: x,
            top: y,
            width: size,
            height: size,
        }}
    >
        <g>
            <motion.circle
                cx={256}
                cy={256}
                fill="rgb(69,162,240,0.5)"
                initial="before"
                variants={{
                    before: {
                        r: 0,
                    },
                    after: {
                        r: 0,
                    },
                    active: {
                        r: 256,
                    },
                }}
            />
            <motion.circle
                cx={256}
                cy={256}
                fill="rgb(69,162,240,1.0)"
                initial="before"
                variants={{
                    before: {
                        r: 0,
                    },
                    after: {
                        r: 0,
                    },
                    active: {
                        r: 128,
                    },
                }}
            />
        </g>
    </svg>
);

export default BlueDot;
