import { useState, useEffect } from 'react';

export default function usePulse(interval = 3000, duration = 200) {
    const [pulsed, setPulsed] = useState(false);

    useEffect(() => {
        let timeout;

        function off() {
            setPulsed(false);

            timeout = setTimeout(on, interval - duration);
        }

        function on() {
            setPulsed(true);

            timeout = setTimeout(off, duration);
        }

        on();

        return () => {
            if (timeout) clearTimeout(timeout);
        };
    }, []);

    return pulsed;
}
