/* eslint-disable no-nested-ternary */
import React from 'react';
import { motion } from 'framer-motion';

import { useFrameContext } from '../FrameController';

export const Slide = ({ enter, exit, children, overlay = true }) => {
    const { index: frame } = useFrameContext();

    const stage = frame < enter ? 'before' : exit < frame ? 'after' : 'active';

    return (
        <motion.div
            css={{
                position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 10,
            }}
            style={{
                backgroundColor: overlay ? 'rgba(0,0,0,0.25)' : '',
            }}
            initial="before"
            animate={stage}
            variants={{
                before: {
                    opacity: 0,
                },
                active: {
                    opacity: 1,
                    transition: {
                        duration: 0.8,
                    },
                },
                after: {
                    opacity: 0,
                    transition: { duration: 0.4 },
                },
            }}
        >
            {children(stage)}
        </motion.div>
    );
};

export default Slide;
