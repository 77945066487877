import { useState, useEffect } from 'react';

function calculateScale() {
    if (typeof window === 'undefined') return 1;
    return Math.max(window.innerWidth / 1920, window.innerHeight / 1080);
}

export function useScale() {
    const [scale, setScale] = useState(calculateScale());

    useEffect(() => {
        let pending = false;
        function onResize() {
            if (!pending) {
                window.requestAnimationFrame(() => {
                    setScale(calculateScale());

                    pending = false;
                });

                pending = true;
            }
        }

        window.addEventListener('resize', onResize);

        return function dispose() {
            window.removeEventListener('resize', onResize);
        };
    }, []);

    let left = 0;
    let top = 0;

    if (typeof window !== 'undefined') {
        left = 50 * (1 - window.innerWidth / (scale * 1920));
        top = 50 * (1 - window.innerHeight / (scale * 1080));
    }

    return [scale, { top, right: left, bottom: top, left }];
}

export default useScale;
